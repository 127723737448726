<template>
    <div class="pcContentOne">
        <img src="http://iv.okvu.cn/vugw/img/pclbBg.jpg" alt="" class="pcoBg" />
        <!-- 标题 -->
        <div class="pcoTitle">
            <p class="pcoBig">网站技术手段，如何展现产品？</p>
            <p class="pcoSmall">营造更加直观的氛围和产品的感染力，促使商家及消费者对产品产生采购欲望，从而促进企业销售。</p>
        </div>
        <!-- 3D展示图 -->
        <!-- wow animate__animated animate__slideInUp -->
        <div class="pco3Dimg">
            <el-carousel
                arrow="never"
                :autoplay="false"
                indicator-position="none"
                class="pcoSwiper wow animate__animated animate__slideInUp"
                :interval="3000"
                type="card"
                ref="carousel"
            >
                <template v-for="el in getAdvData(38).children">
                    <el-carousel-item v-if="el.image" :key="el.id">
                        <div class="iii" style="top: 0px">
                            <a :href="el.url" target="_blank" rel="noopener noreferrer">
                                <img :src="el.image" />
                            </a>
                        </div>
                    </el-carousel-item>
                </template>
            </el-carousel>
        </div>
        <div class="prev wow animate__animated animate__slideInUp" @click="$refs['carousel'].prev()">
            <img src="https://iv.okvu.cn/vugw/img/vu618.png" alt="" />
        </div>
        <div class="next wow animate__animated animate__slideInUp" @click="$refs['carousel'].next()">
            <img src="https://iv.okvu.cn/vugw/img/vu618.png" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    name: "PcContentOne",
    inject: ["getAdvData"],
    data() {
        return {
            imgList: [
                "http://iv.okvu.cn/vugw/img/pclb1.png",
                "http://iv.okvu.cn/vugw/img/pclb2.png",
                "http://iv.okvu.cn/vugw/img/pclb3.png",
                "http://iv.okvu.cn/vugw/img/pclb4.png",
                "http://iv.okvu.cn/vugw/img/pclb5.png",
            ],
        };
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.pcContentOne {
    width: 100%;
    height: calc(100% + 10px);
    margin: 0 auto;
    position: relative;
    // background: url("vu818.com/cn/images/bg.jpg") center;
    .pcoBg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        object-fit: cover;
    }
    // 标题
    .pcoTitle {
        width: 50%;
        height: 80px;
        text-align: center;
        margin: 0 auto;
        padding-top: 20px;
        .pcoBig {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 30px;
            font-weight: 900;
        }
        .pcoSmall {
            width: 100%;
            height: 30px;
            line-height: 30px;
            color: gray;
        }
    }
    // 3D展示图
    .pco3Dimg {
        max-width: 1440px;
        width: 100%;
        height: 50%;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        .pcoSwiper {
            height: 100%;
            /deep/.el-carousel__container {
                height: 100%;
            }
            .iii {
                border-radius: 20px;
                overflow: hidden;
                width: 100%;
                height: 100%;
                position: relative;
            }
            img {
                position: absolute;
                left: 0px;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                min-height: 100%;
            }
        }
    }
    .prev {
        position: absolute;
        bottom: 5%;
        left: 22%;
        width: 105px;
        height: 80px;
        overflow: hidden;
        cursor: pointer;
        z-index: 9;
        & > img {
            position: absolute;
            left: -823px;
            top: -1140px;
        }
    }
    .next {
        position: absolute;
        bottom: 5%;
        right: 22%;
        width: 105px;
        height: 80px;
        overflow: hidden;
        cursor: pointer;
        z-index: 9;
        & > img {
            position: absolute;
            left: -957px;
            top: -1140px;
        }
    }
}
</style>
