<template>
    <div class="thePC">
        <div id="fp" class="fp">
            <!-- 内容一 -->
            <div class="section"><PcContentOne class="content"></PcContentOne></div>
            <!-- 内容二 -->
            <div class="section"><PcContentTwo class="content"></PcContentTwo></div>
            <!-- 内容三 -->
            <div class="section"><PcContentThree class="content"></PcContentThree></div>
            <!-- 内容四 -->
            <div class="section"><PcContentFour class="content"></PcContentFour></div>
            <!-- 内容五 -->
            <div class="section"><PcContentFive class="content"></PcContentFive></div>
            <!-- 内容六 -->
            <div class="section"><PcContentSix :open="open" class="content"></PcContentSix></div>
            <!-- 页面底部 -->
            <div class="section no" style="overflow: auto"><WebBottom></WebBottom></div>
        </div>
    </div>
</template>

<script>
import PcContentOne from "./PcContent/PcContentOne.vue";
import PcContentTwo from "./PcContent/PcContentTwo.vue";
import PcContentThree from "./PcContent/PcContentThree.vue";
import PcContentFour from "./PcContent/PcContentFour.vue";
import PcContentFive from "./PcContent/PcContentFive.vue";
import PcContentSix from "./PcContent/PcContentSix.vue";
import fullpage from "fullpage.js";
import WebBottom from "@/components/common/WebBottom/WebBottom.vue";

export default {
    name: "ThePC",
    components: {
        PcContentOne,
        PcContentTwo,
        PcContentThree,
        PcContentFour,
        PcContentFive,
        PcContentSix,
        WebBottom,
    },
    data() {
        return {
            titleIndex: 0,
            fp: {},
        };
    },
    mounted() {
        let thePC = document.getElementsByClassName("thePC");
        let contents = document.getElementsByClassName("content");
        // let titleF_Bt = contents[0].getBoundingClientRect().top;
        let titleF_St = contents[0].scrollTop;
        let maxBox_st = thePC[0].scrollTop;
        // let maxBox_bt = thePC[0].getBoundingClientRect().top;
        // console.log(contents[0]);
        window.addEventListener("scroll", () => {
            // console.log(titleF_St, titleF_Bt, maxBox_st, maxBox_bt, '11111111111');
        });
        // ------
        this.fp = new fullpage("#fp", {
            navigation: true,
            navigationPosition: "left",
            navigationTooltips: ["一 01", "一 02", "一 03", "一 04", "一 05", "一 06"],
            paddingTop: "85px",
            // normalScrollElements: '.no',
        });
        // 顶部白底
        this.$bus.$emit("alsoWhite", true);
        // 修改导航颜色
        this.$nextTick(() => {
            const navBullets = document.querySelectorAll(".fp-sr-only");
            navBullets.forEach(el => {
                el.nextElementSibling.style.background = "#7781f1";
                // el.style.background = '#7781f1';
            });
        });
    },
    methods: {
        open() {
            this.fp.moveSectionUp();
        },
    },
    beforeDestroy() {
        this.$bus.$emit("alsoWhite", false);
        this.fp.destroy("all");
    },
};
</script>

<style lang="less" scoped>
.thePC {
    min-width: 1423px;
    margin: 0 auto;
    // min-width: 1423px;
    // margin-top: 87px;
}
</style>
