<template>
    <div class="pcContentSix">
        <img src="http://iv.okvu.cn/vugw/img/bg1 (1).jpg" alt="" class="pcBg" />
        <div class="pcNum">05</div>
        <div class="pcsBox">
            <!-- 左边大图片 -->
            <div class="pcsLeftImg">
                <img class="pcsImg" src="http://iv.okvu.cn/vugw/img/tpc51.png" alt="" />
            </div>
            <!-- 右边文字 -->
            <div class="pcsRightText">
                <div class="pcsIcon">
                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="pcsBig">数据检测分析技术</div>
                <div class="pcsSmall">
                    让企业准确洞察热点产品及忽视产品，分析未来未来适销、滞销情况。基于产品图文浏览、点击的数据分析、人群画像，近几年产生了很多成熟模型。用产品型网站，借助大数据分析技术，让企业明明白白地知道如何优化网站内容、调整推广渠道、甚至调整热卖产品的结构。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PcContentSix",
    components: {},
    data() {
        return {};
    },
    props: {
        open: Function,
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
        let _this = this;
        _this.$el.addEventListener(
            "scroll",
            function (e) {
                console.log(e);
                if (this.scrollTop <= 0) {
                    _this.open();
                }
            },
            false
        );
    },
};
</script>

<style lang="less" scoped>
.pcContentSix {
    width: 100%;
    height: 100%;

    margin-top: 5px;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    .pcBg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        object-fit: cover;
    }
    .pcNum {
        position: absolute;
        right: -22px;
        top: -90px;
        font-family: "DIN-Medium";
        font-size: 400px;
        color: #ff6c00;
        opacity: 0.05;
    }
    .pcsBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左边大图片
        .pcsLeftImg {
            width: 755px;
            height: 367px;
            float: left;
            margin-top: 5%;
            margin-left: -30px;
            .pcsImg {
                width: 100%;
                margin-top: 70px;
            }
        }
        // 右边文字
        .pcsRightText {
            width: 35%;
            height: 400px;
            float: right;
            margin-right: 50px;
            margin-top: 150px;
            text-align: left;
            .pcsIcon {
                width: 60px;
                height: 75px;
                overflow: hidden;
                position: relative;
                img {
                    position: absolute;
                    top: -74px;
                    right: -610px;
                }
            }
            .pcsBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 36px;
            }
            .pcsSmall {
                width: 100%;
                height: 100px;
                margin-top: 20px;
                line-height: 30px;
                color: gray;
            }
        }
    }
}
</style>
