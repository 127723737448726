<template>
    <div class="pcContentTwo">
        <img src="http://iv.okvu.cn/vugw/img/bg1 (1).jpg" alt="" class="pcBg" />
        <div class="pcNum">01</div>
        <div class="pctBox">
            <!-- 左边大图片 -->
            <div class="pctLeftImg">
                <img class="pcrBigImg" src="http://iv.okvu.cn/vugw/img/pcdn.png" alt="" />
                <ul>
                    <li class="pclLiImg1" v-show="showLImg === 1">
                        <img class="pclImg" src="http://iv.okvu.cn/vugw/img/tpc1.jpg" alt="" />
                    </li>
                    <li class="pclLiImg2" v-show="showLImg === 2">
                        <img class="pclImg" src="http://iv.okvu.cn/vugw/img/tpc2.jpg" alt="" />
                    </li>
                    <li class="pclLiImg3" v-show="showLImg === 3">
                        <img class="pclImg" src="http://iv.okvu.cn/vugw/img/tpc3.jpg" alt="" />
                    </li>
                    <li class="pclLiImg4" v-show="showLImg === 4">
                        <img class="pclImg" src="http://iv.okvu.cn/vugw/img/tpc4.jpg" alt="" />
                    </li>
                </ul>
            </div>
            <!-- 右边文字小图片 -->
            <div class="pctRightText">
                <!-- 文字 -->
                <div class="pcrTop">
                    <div class="pcrIcon">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                    </div>
                    <div class="pcrBig">HTML5技术增强产品的动画及多媒体效果</div>
                    <div class="pcrSmall">
                        企业向充分展现主打产品时，过去常使用flash做多媒体效果，但牺牲了网站搜索引擎效果；现在响应式技术即可以实现多媒体效果，又不影响网站体验，并且更加吻合人的操作。
                    </div>
                </div>
                <!-- 小图片 -->
                <div class="pcrBottom">
                    <ul>
                        <li class="pcrLiImg" @mousemove="showLeft(1)">
                            <img class="pcrImg" src="http://iv.okvu.cn/vugw/img/tpc1.jpg" alt="" />
                        </li>
                        <li class="pcrLiImg" @mousemove="showLeft(2)">
                            <img class="pcrImg" src="http://iv.okvu.cn/vugw/img/tpc2.jpg" alt="" />
                        </li>
                        <li class="pcrLiImg" @mousemove="showLeft(3)">
                            <img class="pcrImg" src="http://iv.okvu.cn/vugw/img/tpc3.jpg" alt="" />
                        </li>
                        <li class="pcrLiImg" @mousemove="showLeft(4)">
                            <img class="pcrImg" src="http://iv.okvu.cn/vugw/img/tpc4.jpg" alt="" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PcContentTwo",
    data() {
        return {
            showLImg: 1,
        };
    },
    methods: {
        showLeft(i) {
            this.showLImg = i;
        },
    },
};
</script>

<style lang="less" scoped>
.pcContentTwo {
    width: 100%;
    height: 100%;

    position: relative;
    overflow: hidden;
    .pcBg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        object-fit: cover;
    }
    .pcNum {
        position: absolute;
        right: -22px;
        top: -90px;
        font-family: "DIN-Medium";
        font-size: 400px;
        color: #ff6c00;
        opacity: 0.05;
    }
    .pctBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左边大图片
        .pctLeftImg {
            width: 606px;
            height: 500px;
            margin-top: 100px;
            float: left;
            position: relative;
            overflow: hidden;
            .pcrBigImg {
                width: 606px;
                height: 500px;
            }
            ul {
                width: 100%;
                //   height: 100%;
                .pclLiImg1,
                .pclLiImg2,
                .pclLiImg3,
                .pclLiImg4 {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 13px;
                    left: 0px;
                    .pclImg {
                        width: 582px;
                        height: 357px;
                        border-radius: 5px;
                    }
                }
            }
        }
        // 右边文字小图片
        .pctRightText {
            width: 555px;
            height: 100%;
            float: right;
            margin-right: 50px;
            text-align: left;
            // 文字
            .pcrTop {
                width: 100%;
                height: 300px;
                margin-top: 100px;
                .pcrIcon {
                    width: 73px;
                    height: 75px;
                    overflow: hidden;
                    position: relative;
                    img {
                        position: absolute;
                        top: -140px;
                        left: -178px;
                    }
                }
                .pcrBig {
                    width: 100%;
                    height: 95px;
                    line-height: 50px;
                    font-size: 36px;
                }
                .pcrSmall {
                    width: 100%;
                    height: 100px;
                    margin-top: 20px;
                    line-height: 30px;
                    color: gray;
                }
            }
            // 小图片
            .pcrBottom {
                width: 100%;
                height: 68px;
                margin-top: 20px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .pcrLiImg {
                        width: 22%;
                        height: 100%;
                        transition: 0.5s;
                        cursor: pointer;
                        border-radius: 20px;
                        overflow: hidden;
                        .pcrImg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .pcrLiImg:hover {
                        box-shadow: 1px 1px 10px 1px gray;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}
</style>
