<template>
    <div class="pcContentFour">
        <img src="http://iv.okvu.cn/vugw/img/bg1 (1).jpg" alt="" class="pcBg" />
        <div class="pcNum">03</div>
        <div class="pcfBox">
            <!-- 左边大图片 -->
            <div class="pcfLeftImg">
                <img class="pcfImg" src="http://iv.okvu.cn/vugw/img/tpc31.png" alt="" />
            </div>
            <!-- 右边文字 -->
            <div class="pcfRightText">
                <div class="pcfIcon">
                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="pcfBig">小程序雷达技术</div>
                <div class="pcfSmall">
                    将普通网民高效转化为客户，到让产品被看到，被喜欢，被订购；由客户产生想要欲望，变成订购行为。一物一码，营销方式更精准。
                </div>
                <div class="pcfCode">
                    <img class="codeImg" :src="getWebData().shop_qrcode" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PcContentFour",
    inject: ["getWebData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.pcContentFour {
    width: 100%;
    height: 100%;

    margin-top: 5px;
    position: relative;
    overflow: hidden;
    .pcBg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        object-fit: cover;
    }
    .pcNum {
        position: absolute;
        right: -22px;
        top: -90px;
        font-family: "DIN-Medium";
        font-size: 400px;
        color: #ff6c00;
        opacity: 0.05;
    }
    .pcfBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
    }
    // 左边大图片
    .pcfLeftImg {
        width: 58%;
        height: 80%;
        float: left;
        margin-top: 5%;
        .pcfImg {
            margin-top: 70px;
        }
    }
    // 右边文字
    .pcfRightText {
        width: 40%;
        height: 400px;
        float: right;
        margin-top: 100px;
        text-align: left;
        .pcfIcon {
            width: 60px;
            height: 75px;
            overflow: hidden;
            position: relative;
            img {
                position: absolute;
                top: -135px;
                left: 3px;
            }
        }
        .pcfBig {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 36px;
        }
        .pcfSmall {
            width: 100%;
            height: 100px;
            margin-top: 20px;
            line-height: 30px;
            color: gray;
        }
        .pcfCode {
            width: 150px;
            text-align: center;
            border: 1px solid #eee;
            padding: 10px;
            background: #fff;
            .codeImg {
                width: 130px;
            }
        }
    }
}
</style>
