<template>
    <div class="pcContentThree">
        <img src="http://iv.okvu.cn/vugw/img/bg1 (1).jpg" alt="" class="pcBg" />
        <div class="pcNum">02</div>
        <div class="pctBox">
            <!-- 左边文字小图片 -->
            <div class="pcTLeftText">
                <!-- 文字 -->
                <div class="pcTTop">
                    <div class="pcTIcon">
                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                    </div>
                    <div class="pcTBig">VR交互体验设计，增加产品的模拟体验</div>
                    <div class="pcTSmall">
                        对于需要展现产品立体面、或者空间感的产品，过去需要花很大代价才能实现虚拟现实效果。当今VR交互体验设计很成熟，能满足大多数需要，并且可以非常经济地完成VR产品展示、VR空间展示。
                    </div>
                </div>
                <!-- 小图片 -->
                <div class="pcTBottom">
                    <ul>
                        <li class="pcTLiImg" @mousemove="showRight(1)">
                            <img class="pcTImg" src="http://iv.okvu.cn/vugw/img/tpc21.jpg" alt="" />
                        </li>
                        <li class="pcTLiImg" @mousemove="showRight(2)">
                            <img class="pcTImg" src="http://iv.okvu.cn/vugw/img/tpc22.jpg" alt="" />
                        </li>
                        <li class="pcTLiImg" @mousemove="showRight(3)">
                            <img class="pcTImg" src="http://iv.okvu.cn/vugw/img/tpc23.jpg" alt="" />
                        </li>
                        <li class="pcTLiImg" @mousemove="showRight(4)">
                            <img class="pcTImg" src="http://iv.okvu.cn/vugw/img/tpc24.jpg" alt="" />
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 右边大图片 -->
            <div class="pcTRightImg">
                <img class="pcTBigImg" src="http://iv.okvu.cn/vugw/img/pcsj.png" alt="" />
                <ul>
                    <li class="pcRLiImg1" v-show="showRImg === 1">
                        <img class="pcRImg" src="http://iv.okvu.cn/vugw/img/tpc21b.jpg" alt="" />
                    </li>
                    <li class="pcRLiImg2" v-show="showRImg === 2">
                        <img class="pcRImg" src="http://iv.okvu.cn/vugw/img/tpc22b.jpg" alt="" />
                    </li>
                    <li class="pcRLiImg3" v-show="showRImg === 3">
                        <img class="pcRImg" src="http://iv.okvu.cn/vugw/img/tpc23b.jpg" alt="" />
                    </li>
                    <li class="pcRLiImg4" v-show="showRImg === 4">
                        <img class="pcRImg" src="http://iv.okvu.cn/vugw/img/tpc24b.jpg" alt="" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PcContentThree",
    data() {
        return {
            showRImg: 1,
        };
    },
    methods: {
        showRight(i) {
            this.showRImg = i;
        },
    },
};
</script>

<style lang="less" scoped>
.pcContentThree {
    width: 100%;
    height: 100%;

    position: relative;
    overflow: hidden;
    .pcBg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        object-fit: cover;
    }
    .pcNum {
        position: absolute;
        right: -22px;
        top: -90px;
        font-family: "DIN-Medium";
        font-size: 400px;
        color: #ff6c00;
        opacity: 0.05;
    }
    .pctBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
    }
    // 左边文字小图片
    .pcTLeftText {
        width: 555px;
        height: 100%;
        float: left;
        margin-left: 50px;
        text-align: left;
        // 文字
        .pcTTop {
            width: 100%;
            height: 300px;
            margin-top: 100px;
            .pcTIcon {
                width: 55px;
                height: 75px;
                overflow: hidden;
                position: relative;
                img {
                    position: absolute;
                    top: -130px;
                    left: -60px;
                }
            }
            .pcTBig {
                width: 100%;
                height: 95px;
                line-height: 50px;
                font-size: 36px;
            }
            .pcTSmall {
                width: 100%;
                height: 100px;
                margin-top: 20px;
                line-height: 30px;
                color: gray;
            }
        }
        // 图片
        .pcTBottom {
            width: 100%;
            height: 68px;
            margin-top: 20px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .pcTLiImg {
                    width: 22%;
                    height: 100%;
                    transition: 0.5s;
                    cursor: pointer;
                    border-radius: 20px;
                    overflow: hidden;
                    .pcTImg {
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                    }
                }
                .pcTLiImg:hover {
                    box-shadow: 1px 1px 10px 1px gray;
                    box-sizing: border-box;
                }
            }
        }
    }
    // 右边大图片
    .pcTRightImg {
        width: 378px;
        height: 633px;
        margin-right: 200px;
        margin-top: 50px;
        float: right;
        position: relative;
        overflow: hidden;
        .pcTBigImg {
            width: 100%;
            height: 100%;
        }
        ul {
            width: 100%;
            .pcRLiImg1,
            .pcRLiImg2,
            .pcRLiImg3,
            .pcRLiImg4 {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 17px;
                left: 0px;
                .pcRImg {
                    width: 238px;
                    height: 400px;
                    border-radius: 5px;
                }
            }
        }
    }
}
</style>
